export const images = [
  {
    src: "/images/events/march/highlights/1.jpeg",
    original: "/images/events/march/highlights/1.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/2.jpeg",
    original: "/images/events/march/highlights/2.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/3.jpeg",
    original: "/images/events/march/highlights/3.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/4.jpeg",
    original: "/images/events/march/highlights/4.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/5.jpeg",
    original: "/images/events/march/highlights/5.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/6.jpeg",
    original: "/images/events/march/highlights/6.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/7.jpeg",
    original: "/images/events/march/highlights/7.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/8.jpeg",
    original: "/images/events/march/highlights/8.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/9.jpeg",
    original: "/images/events/march/highlights/9.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/10.jpeg",
    original: "/images/events/march/highlights/10.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/11.jpeg",
    original: "/images/events/march/highlights/11.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/12.jpeg",
    original: "/images/events/march/highlights/12.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/13.jpeg",
    original: "/images/events/march/highlights/13.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/14.jpeg",
    original: "/images/events/march/highlights/14.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/15.jpeg",
    original: "/images/events/march/highlights/15.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/16.jpeg",
    original: "/images/events/march/highlights/16.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/17.jpeg",
    original: "/images/events/march/highlights/17.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/18.jpeg",
    original: "/images/events/march/highlights/18.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/19.jpeg",
    original: "/images/events/march/highlights/19.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/20.jpeg",
    original: "/images/events/march/highlights/20.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/21.jpeg",
    original: "/images/events/march/highlights/21.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/22.jpeg",
    original: "/images/events/march/highlights/22.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/23.jpeg",
    original: "/images/events/march/highlights/23.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/march/highlights/24.jpeg",
    original: "/images/events/march/highlights/24.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  }
];