import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            bg: {
                translations: {
                    "EVENTS": "СЪБИТИЯ",
                    "HIGHLIGHTS": "АКЦЕНТИ",
                    "Highlights From Last Month": "Акценти от предишния месец",
                    "PHOTOS": "СНИМКИ",
                    "Club Photos": "Снимки от клуба",
                    "RESERVE": "РЕЗЕРВАЦИЯ",
                    "Create Reservation": "Направете резервация",
                    "CONTACT": "КОНТАКТ",
                    "Contact Us": "Сеържете се с нас",
                    "MENU": "МЕНЮ",
                    "Our Menu": "Нашето Меню",
                    "FOLLOW US": "ПОСЛЕДВАЙТЕ НИ",
                    "Back": "ОБРАТНО КЪМ НАЧАЛНАТА СТРАНИЦА",
                    "Address": "Адрес",
                    "Address Line": "Площад България 1, Национален Дворец на Културата - НДК, етаж 2. Срещу хотел Хилтън София",
                    "Phone": "Телефон",
                    "Copyright": "Copyright © 2025 Кълчър Бийт ООД",
                    "Name": "Име",
                    "Date": "Дата",
                    "Time": "Час",
                    "Additional Information": "Допълнителна информация",
                    "Sending Message": "Запитването се изпраща...",
                    "Success Message": "Запитването беше изпратено успешно!",
                    "Error Message": "Запитването не беше изпратено, поради техническа грешка! Моля, сържете се с нас чрез телефон или мейл!",
                    "Number of guests": "Брой гости",
                    "Фирмено Събитие": "Фирмено Събитие",
                    "DJ Парти": "DJ Парти",
                    "Личен Повод": "Личен Повод",
                    "January": "Януари",
                    "February": "Февруари",
                    "March": "Март",
                    "April": "Април",
                    "May": "Май",
                    "June": "Юни",
                    "July": "Юли",
                    "August": "Август",
                    "September": "Септември",
                    "October": "Октомври",
                    "November": "Ноември",
                    "December": "Декември",
                    "Thursday": "Четвъртък",
                    "Friday": "Петък",
                    "Saturday": "Събота",
                    "Sunday": "Неделя",
                    "Next Event": "Следващо събитие",
                    "Days": "Дни",
                    "Hours": "Часове",
                    "Minutes": "Минути",
                    "Seconds": "Секунди",
                    "UPCOMING EVENTS": "ПРЕДСТОЯЩИ СЪБИТИЯ",
                    "CULTURE BEAT": "КЪЛЧЪР БИЙТ",
                    "SUMMER TERRACE": "ЛЯТНА ТЕРАСА",
                    "CORPORATE EVENTS": "КОРПОРАТИВНИ СЪБИТИЯ",
                    "PERSONAL EVENTS": "ЛИЧНИ ПОВОДИ",
                    "Corporate Event": "Корпоративно Събитие",
                    "DJ Party": "DJ Парти",
                    "Personal Event": "Личен повод",
                    "Culture Beat Description": "Разнообразете Вашите фирмени и корпоративни събития. Предлагаме професионална организация, която включва кетъринг, водещи и артисти. ",
                    "Summer Terrace Beat Description": "Латната тераса предлага невероятна панорамна гледка към централна София и Витоша. Летни коктейли, прията музика и незабравими DJ партита.",
                    "Corporate Event Reservation Description": "Разнообразете Вашите фирмени и корпоративни събития, като оставите всичко в нашите ръце. Предлагаме кетъринг, водещи и артисти. ",
                    "DJ Party Reservation Description": "Готови ли сте да се потопите в света на нощния живот? Резервирайте място и се забавлявайте с най-доброто, което може да ви предложи клубния живот на София!",
                    "Personal Event Reservation Description": "Нашият екип е на разположение, за да направи Вашето лично събитие специално и незабравимо. Рожден ден, юбилей или друг повод.",
                    "PAGE NOT FOUND": "СТРАНИЦАТА НЕ Е НАМЕРЕНА",
                    "The page you are looking for is not available.": "Страницата, която търсите не съществува.",
                    "Past Event": "Отминало Събитие",
                    "BACK TO EVENTS": "Всички събития",
                    "Mix Description": "Culture Beat Mix - Миксовете на нашите DJ в партньорство с Радио Енерджи",
                    "Christmas Party": "Коледно парти",
                    "Christmas Party Description": "Планирайте своето коледно или новогодишно празненсво, докато все още има свободни дати. Екипът ни ще се погрижи за всичко, от което имате нужда.",
                    "Other Event": "Друго събитие",
                    "Other Event Description": "Свържете се с нас за всякакъв вид събития и презентации, за да ги обсъдим и организираме спрямо изискванията ви.",
                    "CookiePolicy": "Този сайт използва бисквитки (cookies), за да ви предоставим по-качествена услуга.",
                    "MIX": "РАДИО",
                    "Rate Us On Google": "Оценете ни в Google",
                    "Fully Booked": "Няма свободни места."
                }
            },
            en: {
                translations: {
                    "EVENTS": "EVENTS",
                    "HIGHLIGHTS": "HIGHLIGHTS",
                    "Highlights From Last Month": "Highlights From Last Month",
                    "PHOTOS": "PHOTOS",
                    "Club Photos": "Club Photos",
                    "RESERVE": "RESERVE",
                    "Create Reservation": "Create Reservation",
                    "CONTACT": "CONTACT",
                    "Contact Us": "Contact Us",
                    "MENU": "MENU",
                    "Our Menu": "Our Menu",
                    "FOLLOW US": "FOLLOW US",
                    "Address": "Address",
                    "Address Line": "1 Bulgaria Square, NDK(National Palace of Culture), Sofia, Bulgaria. Second floor. Facing Hilton Sofia Hotel",
                    "Phone": "Phone",
                    "Back": "BACK TO MAIN PAGE",
                    "Copyright": "Copyright © 2025 Culture Beat ltd.",
                    "Name": "Name",
                    "Date": "Date",
                    "Time": "Time",
                    "Number of guests": "Number of guests",
                    "Фирмено Събитие": "Corporate Event",
                    "DJ Парти": "Dj Party",
                    "Личен Повод": "Personal Event",
                    "Additional Information": "Additional Information",
                    "Sending Message": "Your request is being sent...",
                    "Success Message": "Your request has been successfully sent!",
                    "Error Message": "Your request has not been sent, due to technical issue! Please contact us via phone or email!",
                    "January": "January",
                    "February": "February",
                    "March": "March",
                    "April": "April",
                    "May": "May",
                    "June": "June",
                    "July": "July",
                    "August": "August",
                    "September": "September",
                    "October": "October",
                    "November": "November",
                    "December": "December",
                    "Thursday": "Thursday",
                    "Friday": "Friday",
                    "Saturday": "Saturday",
                    "Sunday": "Sunday",
                    "Next Event": "Next Event",
                    "Days": "Days",
                    "Hours": "Hours",
                    "Minutes": "Minutes",
                    "Seconds": "Seconds",
                    "UPCOMING EVENTS": "UPCOMING EVENTS",
                    "CULTURE BEAT": "CULTURE BEAT",
                    "SUMMER TERRACE": "SUMMER TERRACE",
                    "CORPORATE EVENTS": "CORPORATE EVENTS",
                    "PERSONAL EVENTS": "PERSONAL EVENTS",
                    "Corporate Event": "Corporate Event",
                    "DJ Party": "DJ Party",
                    "Personal Event": "Personal Event",
                    "Culture Beat Description": "Welcome to Culture Beat Club, Sofia's hot-spot for unforgettable nights, situated right in the iconic National Palace of Culture. ",
                    "Summer Terrace Beat Description": "The Summer Terrasse offers great panoramic view, tasty food, summer cocktails and relaxing music during the day and the evening parties.",
                    "Corporate Event Reservation Description": "Elevate your corporate events with our exceptional services. We provide expert organization, including catering and hosts, to ensure a memorable experience. ",
                    "DJ Party Reservation Description": "Are you ready to dive into the Sofia night life? Book your table and enjoy the best party in our town.",
                    "Personal Event Reservation Description": "Birthday, anniversary or any other celebration. Together we will create a unique experience. Our team is at your service.",
                    "PAGE NOT FOUND": "СТРАНИЦАТА НЕ Е НАМЕРЕНА",
                    "The page you are looking for is not available.": "The page you are looking for is not available.",
                    "Past Event": "Past Event",
                    "BACK TO EVENTS": "All Events",
                    "Mix Description": "Culture Beat Mix - Our Best DJs Mixes, Played On Radio Energy",
                    "Christmas Party": "Christmas Party",
                    "Christmas Party Description": "Plan your New Year's Eve or Christmas Party, while there are still dates available. Our team will take care of everything you need.",
                    "Other Event": "Other Event",
                    "Other Event Description": "Contact us regarding presentations or any other event, to discuss your ideas and organise your party according to your needs.",
                    "CookiePolicy": "This website uses cookies to enhance the user experience.",
                    "MIX": "RADIO",
                    "Rate Us On Google": "Rate us on Google",
                    "Fully Booked": "Fully Booked"
                }
            }
        },
        fallbackLng: "bg",
        debug: true,
        order: ['path'],
        detection: {
            order: ["path", "localStorage", "htmlTag", "subdomain"],
            lookupFromPathIndex: 0,
            checkWhitelist: true,
        },
        supportedLngs: ['bg', 'en'],

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;